// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-markdown-js": () => import("./../../../src/layouts/markdown.js" /* webpackChunkName: "component---src-layouts-markdown-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alarms-index-js": () => import("./../../../src/pages/alarms/index.js" /* webpackChunkName: "component---src-pages-alarms-index-js" */),
  "component---src-pages-context-business-unit-js": () => import("./../../../src/pages/context/business-unit.js" /* webpackChunkName: "component---src-pages-context-business-unit-js" */),
  "component---src-pages-context-prefix-js": () => import("./../../../src/pages/context/prefix.js" /* webpackChunkName: "component---src-pages-context-prefix-js" */),
  "component---src-pages-context-site-js": () => import("./../../../src/pages/context/site.js" /* webpackChunkName: "component---src-pages-context-site-js" */),
  "component---src-pages-context-source-js": () => import("./../../../src/pages/context/source.js" /* webpackChunkName: "component---src-pages-context-source-js" */),
  "component---src-pages-datalake-consume-js": () => import("./../../../src/pages/datalake/consume.js" /* webpackChunkName: "component---src-pages-datalake-consume-js" */),
  "component---src-pages-dataset-detail-js": () => import("./../../../src/pages/dataset/detail.js" /* webpackChunkName: "component---src-pages-dataset-detail-js" */),
  "component---src-pages-dataset-index-js": () => import("./../../../src/pages/dataset/index.js" /* webpackChunkName: "component---src-pages-dataset-index-js" */),
  "component---src-pages-dataset-new-js": () => import("./../../../src/pages/dataset/new.js" /* webpackChunkName: "component---src-pages-dataset-new-js" */),
  "component---src-pages-dictionary-detail-js": () => import("./../../../src/pages/dictionary/detail.js" /* webpackChunkName: "component---src-pages-dictionary-detail-js" */),
  "component---src-pages-dictionary-form-js": () => import("./../../../src/pages/dictionary/form.js" /* webpackChunkName: "component---src-pages-dictionary-form-js" */),
  "component---src-pages-dictionary-index-js": () => import("./../../../src/pages/dictionary/index.js" /* webpackChunkName: "component---src-pages-dictionary-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ingest-creating-js": () => import("./../../../src/pages/ingest/creating.js" /* webpackChunkName: "component---src-pages-ingest-creating-js" */),
  "component---src-pages-ingest-detail-js": () => import("./../../../src/pages/ingest/detail.js" /* webpackChunkName: "component---src-pages-ingest-detail-js" */),
  "component---src-pages-ingest-download-js": () => import("./../../../src/pages/ingest/download.js" /* webpackChunkName: "component---src-pages-ingest-download-js" */),
  "component---src-pages-ingest-index-js": () => import("./../../../src/pages/ingest/index.js" /* webpackChunkName: "component---src-pages-ingest-index-js" */),
  "component---src-pages-ingest-new-js": () => import("./../../../src/pages/ingest/new.js" /* webpackChunkName: "component---src-pages-ingest-new-js" */),
  "component---src-pages-ingest-options-js": () => import("./../../../src/pages/ingest/options.js" /* webpackChunkName: "component---src-pages-ingest-options-js" */),
  "component---src-pages-ingest-result-js": () => import("./../../../src/pages/ingest/result.js" /* webpackChunkName: "component---src-pages-ingest-result-js" */),
  "component---src-pages-model-detail-js": () => import("./../../../src/pages/model/detail.js" /* webpackChunkName: "component---src-pages-model-detail-js" */),
  "component---src-pages-role-detail-js": () => import("./../../../src/pages/role/detail.js" /* webpackChunkName: "component---src-pages-role-detail-js" */),
  "component---src-pages-role-edit-js": () => import("./../../../src/pages/role/edit.js" /* webpackChunkName: "component---src-pages-role-edit-js" */),
  "component---src-pages-role-index-js": () => import("./../../../src/pages/role/index.js" /* webpackChunkName: "component---src-pages-role-index-js" */),
  "component---src-pages-role-new-role-js": () => import("./../../../src/pages/role/new-role.js" /* webpackChunkName: "component---src-pages-role-new-role-js" */),
  "component---src-pages-user-detail-js": () => import("./../../../src/pages/user/detail.js" /* webpackChunkName: "component---src-pages-user-detail-js" */),
  "component---src-pages-user-edit-js": () => import("./../../../src/pages/user/edit.js" /* webpackChunkName: "component---src-pages-user-edit-js" */),
  "component---src-pages-user-index-js": () => import("./../../../src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-new-user-js": () => import("./../../../src/pages/user/new-user.js" /* webpackChunkName: "component---src-pages-user-new-user-js" */),
  "component---src-pages-visualization-timeseries-js": () => import("./../../../src/pages/visualization/timeseries.js" /* webpackChunkName: "component---src-pages-visualization-timeseries-js" */)
}

